<template>
    <div class="page">
        <div class="page-header pd">
            <sc-button icon="chevron-left" @click="$router.push(`/app/admin/${resource.route}`)">Back</sc-button>
        </div>
        <div class="pd">
            <sc-form :form="form" @submit="submit()" v-if="form"></sc-form>
        </div>
    </div>
</template>

<script>

export default {
  name: "new",
  props: {
    passed: {
      required: true,
    }
  },

  data() {
    return {
      words: '',
      form: null,
      userLevels: [],
      userGroups: [],
      companies: []
    }
  },

  computed: {
    resource() {
      return this.passed
    }
  },

  mounted() {
    this.form = this.$sform.createForm({
      name: `New ${this.resource.name}`,
      url: this.resource.api,
      fields: {
        first_name: {
          name: 'First name*'
        },
        last_name: {
          name: 'Last name*'
        },
        email: {
          name: 'Email*'
        },
        password: {
          name: 'Password*',
          component: 'nr-password'
        },
        password_confirmation: {
          name: 'Password Confirmation*',
          component: 'nr-password'
        },
        user_level_id: {
          name: 'Select User Level*',
          component: 'nr-select',
          options: {
            empty: true,
            data: this.fetchResource('user-level')
          }
        },
        user_group_id: {
          name: 'Select User Group*',
          component: 'nr-select',
          options: {
            empty: true,
            data: this.fetchResource('user-groups?amount=10000')
          }
        },
        company_id: {
          name: 'Select Company*',
          component: 'nr-select',
          options: {
            empty: true,
            data: this.fetchResource('companies?amount=10000')
          }
        }
      },
      success: () => {
        this.$notify.success(`${this.resource.name} added`)
        this.$router.push(`/app/admin/${this.resource.route}`)
      }
    })
  },

  methods: {
    submit() {
      this.form.post();
    },

    fetchResource(resource) {
      return this.$talker.api(resource)
        .then((res) => {
          return res.data.data;
        })
    }
  }
}
</script>